@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: -webkit-fill-available;
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  color: #111;
  background-color: #fefefe;

  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console,
    monospace;
}
@media (prefers-color-scheme: dark) {
  body {
    color: #eee;
    background-color: #060606;
  }
}
#root {
  min-height: 100vh;
}

.red {
  animation: down 0.5s;
}
.green {
  animation: up 0.5s;
}

@keyframes up {
  0% {
    background-color: transparent;
  }
  10% {
    background-color: rgba(0, 200, 0, 0.1);
  }
  100% {
    background-color: transparent;
  }
}

@keyframes down {
  0% {
    background-color: transparent;
  }
  10% {
    background-color: rgba(200, 0, 0, 0.1);
  }
  100% {
    background-color: transparent;
  }
}

.red-bold {
  animation: down-bold 0.9s;
}
.green-bold {
  animation: up-bold 0.9s;
}

@keyframes up-bold {
  0% {
    background-color: transparent;
  }
  10% {
    background-color: rgba(16, 185, 129, 0.4);
  }
  60% {
    background-color: rgba(16, 185, 129, 0.1);
  }
  100% {
    background-color: transparent;
  }
}

@keyframes down-bold {
  0% {
    background-color: transparent;
  }
  10% {
    background-color: rgba(239, 68, 68, 0.4);
  }
  60% {
    background-color: rgba(239, 68, 68, 0.1);
  }
  100% {
    background-color: transparent;
  }
}

Button {
  font-size: small;
  padding-left: 10px;
  padding-right: 10px;
}

Button:hover {
  color: rgba(16, 185, 129, 0.4);
}